// styles
import './App.css';
import './assets/bridgeover.css';
// router
import MainRouter from './router/MainRouter';
// show until translations are loaded
import { Spinner } from './components/Spinner';
import { Suspense } from 'react';
import { ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Suspense fallback={<Spinner visible />}>
      <MainRouter />
      <ToastContainer
        position="top-center"
        autoClose={8000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="colored"
        transition={Bounce}
        className="bo-w-500"
      />
    </Suspense>
  );
}

export default App;
