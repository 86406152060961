import { Col, Container, Row } from 'reactstrap';
import ContactInfo from '../../components/ContactInfo';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';

function Success() {
  const { t } = useTranslation();

  return (
    <Container className="bo-mobile">
      <Header />
      <div className="bo-rounded-container mt-4">
        <Row className="mt-4 mx-3">
          <Col>
            <p className="bo-text-bold bo-text-large">{t('pages.success.thank_you')}</p>
            <p className="bo-text mb-4">{t('pages.success.your_payment_has_been_accepted')}</p>
            <p className="bo-text">{t('pages.success.for_any_questions')}</p>
          </Col>
        </Row>
        <ContactInfo />
      </div>
    </Container>
  );
}

export default Success;
