/* eslint-disable no-bitwise */
/* eslint-disable no-restricted-globals */
// NOTE:
// This is a copy of the react-otp-input library
// in order to add unfocus on the last input functionality
// https://github.com/devfolioco/react-otp-input
import { Fragment, useEffect, useRef, useState } from 'react';

const isStyleObject = obj => typeof obj === 'object' && obj !== null;

function OtpInput({
  value = '',
  numInputs = 4,
  onChange,
  onPaste,
  renderInput,
  shouldAutoFocus = false,
  inputType = 'text',
  renderSeparator,
  placeholder,
  containerStyle,
  inputStyle,
  skipDefaultStyles = false,
}) {
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef([]);

  const getOTPValue = () => (value ? value.toString().split('') : []);

  const isInputNum = inputType === 'number' || inputType === 'tel';

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, numInputs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numInputs]);

  useEffect(() => {
    if (shouldAutoFocus) {
      inputRefs.current[0]?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAutoFocus]);

  const focusInput = index => {
    const newActiveInput = Math.max(Math.min(numInputs - 1, index), 0);

    if (inputRefs.current[newActiveInput]) {
      inputRefs.current[newActiveInput]?.focus();
      inputRefs.current[newActiveInput]?.select();
      setActiveInput(newActiveInput);
    }
  };

  const handleOTPChange = otp => {
    const otpValue = otp.join('');

    onChange(otpValue);
  };

  const changeCodeAtFocus = v => {
    const otp = getOTPValue();

    // eslint-disable-next-line prefer-destructuring
    otp[activeInput] = v[0];
    handleOTPChange(otp);
  };

  const getPlaceholderValue = () => {
    if (typeof placeholder === 'string') {
      if (placeholder.length === numInputs) {
        return placeholder;
      }

      if (placeholder.length > 0) {
        console.error('Length of the placeholder should be equal to the number of inputs.');
      }
    }

    return undefined;
  };

  const isInputValueValid = v => {
    const isTypeValid = isInputNum ? !isNaN(Number(v)) : typeof v === 'string';

    return isTypeValid && v.trim().length === 1;
  };

  const handleChange = event => {
    const { value: eValue } = event.target;

    if (isInputValueValid(eValue)) {
      changeCodeAtFocus(eValue);

      // NOTE: unfocus the last input on the last entry
      if (activeInput === numInputs - 1 && numInputs - 1 === value.length) {
        inputRefs.current[activeInput]?.blur();
      } else {
        focusInput(activeInput + 1);
      }
    }
  };

  const handleInputChange = event => {
    const { nativeEvent } = event;
    const { value: eValue } = event.target;

    if (!isInputValueValid(eValue)) {
      // Pasting from the native autofill suggestion on a mobile device can pass
      // the pasted string as one long input to one of the cells. This ensures
      // that we handle the full input and not just the first character.
      if (eValue.length === numInputs) {
        const hasInvalidInput = eValue.split('').some(cellInput => !isInputValueValid(cellInput));

        if (!hasInvalidInput) {
          handleOTPChange(eValue.split(''));
          // NOTE:
          // focusInput(numInputs - 1);
        }
      }

      // @ts-expect-error - This was added previously to handle and edge case
      // for dealing with keyCode "229 Unidentified" on Android. Check if this is
      // still needed.
      if (nativeEvent.data === null && nativeEvent.inputType === 'deleteContentBackward') {
        event.preventDefault();
        changeCodeAtFocus('');
        // NOTE:
        // focusInput(activeInput - 1);
      }

      // Clear the input if it's not valid value because firefox allows
      // pasting non-numeric characters in a number type input
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  const handleFocus = event => index => {
    setActiveInput(index);
    event.target.select();
  };

  const handleBlur = () => {
    setActiveInput(activeInput - 1);
  };

  const handleKeyDown = event => {
    const otp = getOTPValue();

    if ([event.code, event.key].includes('Backspace')) {
      event.preventDefault();
      changeCodeAtFocus('');
      focusInput(activeInput - 1);
    } else if (event.code === 'Delete') {
      event.preventDefault();
      changeCodeAtFocus('');
    } else if (event.code === 'ArrowLeft') {
      event.preventDefault();
      focusInput(activeInput - 1);
    } else if (event.code === 'ArrowRight') {
      event.preventDefault();
      focusInput(activeInput + 1);
    }
    // React does not trigger onChange when the same value is entered
    // again. So we need to focus the next input manually in this case.
    else if (event.key === otp[activeInput]) {
      event.preventDefault();
      focusInput(activeInput + 1);
    } else if (
      event.code === 'Spacebar' ||
      event.code === 'Space' ||
      event.code === 'ArrowUp' ||
      event.code === 'ArrowDown'
    ) {
      event.preventDefault();
    }
  };

  const handlePaste = event => {
    event.preventDefault();

    const otp = getOTPValue();

    let nextActiveInput = activeInput;

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = event.clipboardData
      .getData('text/plain')
      .slice(0, numInputs - activeInput)
      .split('');

    // Prevent pasting if the clipboard data contains non-numeric values for number inputs
    if (isInputNum && pastedData.some(v => isNaN(Number(v)))) {
      return;
    }

    // Paste data from focused input onwards
    // eslint-disable-next-line no-plusplus
    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift() ?? '';
        nextActiveInput += 1;
      }
    }

    // NOTE: unfocus the last input on the last entry
    if (nextActiveInput !== numInputs) {
      focusInput(nextActiveInput);
    } else {
      inputRefs.current[activeInput]?.blur();
    }
    handleOTPChange(otp);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        ...(isStyleObject(containerStyle) && containerStyle),
      }}
      className={typeof containerStyle === 'string' ? containerStyle : undefined}
      onPaste={onPaste}
    >
      {Array.from({ length: numInputs }, (_, index) => index).map(index => (
        <Fragment key={index}>
          {renderInput(
            {
              value: getOTPValue()[index] ?? '',
              placeholder: getPlaceholderValue()?.[index] ?? undefined,
              // eslint-disable-next-line no-return-assign
              ref: element => (inputRefs.current[index] = element),
              onChange: handleChange,
              onFocus: event => handleFocus(event)(index),
              onBlur: handleBlur,
              onKeyDown: handleKeyDown,
              onPaste: handlePaste,
              autoComplete: 'off',
              'aria-label': `Please enter OTP character ${index + 1}`,
              style: Object.assign(
                !skipDefaultStyles ? { width: '1em', textAlign: 'center' } : {},
                isStyleObject(inputStyle) ? inputStyle : {},
              ),
              className: typeof inputStyle === 'string' ? inputStyle : undefined,
              type: inputType,
              inputMode: isInputNum ? 'numeric' : 'text',
              onInput: handleInputChange,
            },
            index,
          )}
          {index < numInputs - 1 &&
            (typeof renderSeparator === 'function' ? renderSeparator(index) : renderSeparator)}
        </Fragment>
      ))}
    </div>
  );
}

export default OtpInput;
