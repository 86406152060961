import { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { makeUnauthorizedRequest } from '../../utils/makeRequest';
import { useEnv } from '../../context/env-context';
import { Spinner } from '../../components/Spinner';
import { useTranslation } from 'react-i18next';
import { CodeInput } from '../../components/CodeInput';
import { useNavigate } from 'react-router-dom';
import { useSessionData } from '../../hooks/useSessionData';
import Header from '../../components/Header';

export function Authentication() {
  const navigate = useNavigate();
  const { sessionData, setSessionData, gotoError } = useSessionData();
  const { consumer, linkId } = sessionData();

  const { t, i18n } = useTranslation();

  const { apiOriginFunding } = useEnv();

  const [spinner, setSpinner] = useState(false);
  const [code, setCode] = useState('');
  const [showError, setShowError] = useState(false);

  const sendMfa = async () => {
    setSpinner(true);

    const config = {
      url: `${apiOriginFunding}/ad_hoc_payment/send_mfa`,
      method: 'POST',
      data: JSON.stringify({ paymentLinkId: linkId }),
    };

    try {
      const response = await makeUnauthorizedRequest(config);

      setSessionData('consumer', JSON.stringify(response));
    } catch (error) {
      gotoError(t('errors.send_mfa_error'));
    } finally {
      setSpinner(false);
    }
  };

  const authenticate = async () => {
    setSpinner(true);

    const config = {
      url: `${apiOriginFunding}/ad_hoc_payment/authenticate`,
      method: 'POST',
      data: JSON.stringify({ paymentLinkId: linkId, code }),
    };

    try {
      const response = await makeUnauthorizedRequest(config);

      setSessionData('accessToken', response.accessToken);
      navigate('/payment-details');
    } catch (error) {
      if (error.message === 'Invalid code. Authentication failed.') {
        setShowError(true);
      } else {
        gotoError(t('errors.authenticate_error'));
      }
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    if (consumer && consumer.consumerLanguage) {
      i18n.changeLanguage(consumer.consumerLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumer?.consumerLanguage]);

  return (
    <Spinner visible={spinner} wrapperFlexGrow={1}>
      {consumer && (
        <Container className="bo-mobile">
          <Header />
          <div className="bo-rounded-container mt-4">
            <Row className="mt-4 mx-3">
              <Col>
                <p className="bo-larger text-center">
                  {t('common.consumer_name', { name: consumer.consumerFirstName })}
                </p>
                <p className="bo-text text-center mt-3">
                  {consumer.mfaChannel === 'SMS'
                    ? t('pages.authentication.sms_code')
                    : t('pages.authentication.email_code')}
                </p>
                <p className="bo-text text-center my-4">{consumer.mfaMask}</p>
                <p className="bo-text text-center">{t('pages.authentication.enter_the_code')}</p>
                {showError && (
                  <div className="bo-text bo-payment-error-container">
                    {t('pages.authentication.wrong_code')}
                  </div>
                )}
                <p className="bo-text text-center">{t('pages.authentication.valid_for')}</p>
              </Col>
            </Row>
            <Row className="mt-3 mx-3">
              <Col className="d-flex justify-content-center">
                <CodeInput
                  value={code}
                  onChange={value => {
                    const newValue = value.replace(/\D+/g, '');

                    setCode(newValue);
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-5 mx-3">
              <Col className="d-flex justify-content-center">
                <Button
                  className="bo-button px-4"
                  disabled={code.length !== 6}
                  onClick={() => authenticate()}
                >
                  {t('common.continue')}
                </Button>
              </Col>
            </Row>
            <Row className="mt-2 mx-3">
              <Col className="d-flex justify-content-center">
                <Button className="bo-link" color="link" onClick={() => sendMfa()}>
                  {t('pages.authentication.resend_the_code')}
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </Spinner>
  );
}
export default Authentication;
