import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Auth0ProviderWithRedirectCallback from '../components/Auth0ProviderWithRedirectCallback';
// pages
import Welcome from '../pages/Welcome/Welcome';
import Authentication from '../pages/Authentication/Authentication';
import AdminAccess from '../pages/AdminAccess/AdminAccess';
import PaymentDetails from '../pages/PaymentDetails/PaymentDetails';
import CardForm from '../pages/CardForm/CardForm';
import LinkedCards from '../pages/LinkedCards/LinkedCards';
import Card from '../pages/Card/Card';
import Success from '../pages/Success/Success';
import Error from '../pages/Error/Error';

function MainRouter() {
  return (
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback>
        <div id="app" className="d-flex flex-column h-100">
          <Routes>
            <Route path="/pay/:linkId" element={<Welcome />} />
            <Route path="/auth" element={<Authentication />} />
            <Route path="/admin-access" element={<AdminAccess />} />
            <Route path="/payment-details" element={<PaymentDetails />} />
            <Route path="/card-form" element={<CardForm />} />
            <Route path="/linked-cards" element={<LinkedCards />} />
            <Route path="/card" element={<Card />} />
            <Route path="/success" element={<Success />} />
            <Route path="/error" element={<Error />} />
          </Routes>
        </div>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  );
}

export default MainRouter;
