import { Trans, useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { useSessionData } from '../../hooks/useSessionData';
import ContactInfo from '../../components/ContactInfo';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';

function Error() {
  const { t } = useTranslation();

  const { sessionData } = useSessionData();
  const { errorState } = sessionData();

  if (errorState === 'card' || errorState === 'payment') {
    return (
      <Container className="bo-mobile">
        <Header />
        <div className="bo-rounded-container mt-4">
          <Row className="mt-4 mx-3">
            <Col>
              <div className="bo-text bo-payment-error-container">
                {errorState === 'card'
                  ? t('pages.error.card_error')
                  : t('pages.error.payment_error')}
              </div>
            </Col>
          </Row>
          <Row className="mt-4 mx-3">
            <Col>
              <p className="bo-text mb-3">
                <Trans t={t} i18nKey="pages.error.click_here">
                  Click{' '}
                  <Link to="/linked-cards" className="bo-link">
                    here
                  </Link>{' '}
                  to use a different card or contact BridgeOver support:
                </Trans>
              </p>
            </Col>
          </Row>
          <ContactInfo />
        </div>
      </Container>
    );
  }

  return (
    <Container className="bo-mobile">
      <Header />
      <div className="bo-rounded-container mt-4">
        <Row className="mt-4 mx-3">
          <Col>
            <p className="bo-text mb-3">{t('common.welcome_to_payments')}</p>
            <p className="bo-text">{t('pages.error.error_occurred')}</p>
            <p className="bo-text-bold my-3">{sessionData().error || t('common.unknown_error')}</p>
            <p className="bo-text">{t('pages.error.please_contact')}</p>
          </Col>
        </Row>
        <ContactInfo />
      </div>
    </Container>
  );
}

export default Error;
