import './index.css';
import './services/i18next/i18next';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

if (process.env.NODE_ENV !== 'development') {
  /* eslint-disable */ 
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
  console.error = () => {};
  /* eslint-enable */
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
