import axios from 'axios';

export const makeRequest = async config => {
  const newConfig = config;

  newConfig.headers = {
    'content-type': 'application/json',
    Authorization: `Bearer ${newConfig.token}`,
  };

  try {
    const response = await axios(newConfig, {
      params: newConfig.params,
    });
    const { data } = response;

    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.data.message) {
        console.error('Error option 1', error.response.data.message);
        console.error('Error option 1', error);
        throw new Error(error.response.data.message);
      } else {
        console.error('Error option 2', error.message, error.response.data);
        throw new Error(`${error.message}. Error data: ${JSON.stringify(error.response.data)}`);
      }
    } else {
      console.error('Error option 3', error.message);
      throw new Error(error.message);
    }
  }
};

export const makeUnauthorizedRequest = async config => {
  const newConfig = config;

  newConfig.headers = {
    'content-type': 'application/json',
    Authorization: `Bearer ${newConfig.token}`,
  };

  try {
    const response = await axios(newConfig, {
      params: newConfig.params,
    });
    const { data } = response;

    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.data.message) {
        console.error('Error option 1', error.response.data.message);
        console.error('Error option 1', error);
        throw new Error(error.response.data.message);
      } else {
        console.error('Error option 2', error.message, error.response.data);
        throw new Error(`${error.message}. Error data: ${JSON.stringify(error.response.data)}`);
      }
    } else {
      console.error('Error option 3', error.message);
      throw new Error(error.message);
    }
  }
};
