import { Col, Row } from 'reactstrap';
import { useEnv } from '../context/env-context';
import { version } from '../version';

function EnvironmentFooter() {
  const { envName } = useEnv();

  return (
    <Row className="mb-2 mt-5 mx-3">
      <Col>
        <p className="bo-inline-badge">
          {`Payments v${version.major}.${version.minor}.${version.build} (${envName})`}
        </p>
      </Col>
    </Row>
  );
}

export default EnvironmentFooter;
