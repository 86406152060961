import moment from 'moment';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../components/Spinner';
import { useEnv } from '../../context/env-context';
import { Button, Col, Container, Input, Row } from 'reactstrap';
import { makeRequest } from '../../utils/makeRequest';
import { useSessionData } from '../../hooks/useSessionData';
import Header from '../../components/Header';

function PaymentDetails() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { sessionData, setSessionData, gotoError } = useSessionData();

  const { apiOriginFunding, apiOriginConsumer } = useEnv();

  const [spinner, setSpinner] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(null);
  const [editAmount, setEditAmount] = useState(false);

  const getPaymentDetails = async () => {
    setSpinner(true);

    const config = {
      url: `${apiOriginFunding}/ad_hoc_payment/payment_details`,
      token: sessionData().accessToken,
      method: 'GET',
      params: { paymentLinkId: sessionData().linkId },
    };

    try {
      const response = await makeRequest(config);

      setPaymentDetails(response);
      setPaymentAmount(response.paymentAmount);
      setSessionData('paymentAmount', response.paymentAmount);
    } catch (error) {
      gotoError(t('errors.payment_details_error'));
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getPaymentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCards = async () => {
    setSpinner(true);

    const config = {
      url: `${apiOriginConsumer}/cards/get`,
      token: sessionData().accessToken,
      method: 'GET',
    };

    try {
      const response = await makeRequest(config);

      setSessionData('linkedCards', JSON.stringify(response));

      const linkedAvailableCards = response.filter(card => card.status === 'OK');

      if (linkedAvailableCards) {
        if (linkedAvailableCards.length > 0) {
          navigate('/linked-cards');
          setSpinner(false);
        } else {
          navigate('/card-form');
          setSpinner(false);
        }
      }
    } catch (error) {
      gotoError(t('errors.cards_error'));
      setSpinner(false);
    }
  };

  const getAmountOptions = () => {
    if (paymentDetails) {
      const max = paymentDetails.paymentAmount;
      const min = paymentDetails.paymentMinimalAmount;
      const difference = max - min;

      if (difference <= 0) {
        return [min];
      }

      const options = max % 10 === 0 ? [] : [max];

      let nextOption = Math.floor(max / 10) * 10;

      while (nextOption > min) {
        options.push(nextOption);
        nextOption -= 10;
      }

      options.push(min);

      return options;
    }

    return [];
  };

  const paymentIsNotAvailable =
    paymentDetails && paymentAmount && paymentDetails.fundingOutstandingAmount < paymentAmount;

  return (
    <Spinner visible={spinner} wrapperFlexGrow={1}>
      {paymentDetails && (
        <Container className="bo-mobile">
          <Header />
          <div className="bo-rounded-container mt-4">
            <Row className="mt-4 mx-3">
              <Col>
                <p className="bo-text-bold">{t('pages.payment_details.payment_details')}</p>
              </Col>
            </Row>
            <Row className="mt-3 mx-3">
              <Col>
                <p className="bo-text">{t('pages.payment_details.advance_id')}</p>
              </Col>
              <Col>
                <p className="bo-text">{paymentDetails.fundingId}</p>
              </Col>
            </Row>
            <Row className="mx-3">
              <Col>
                <p className="bo-text">{t('pages.payment_details.name')}</p>
              </Col>
              <Col>
                <p className="bo-text">
                  {`${paymentDetails.payerFirstName} ${paymentDetails.payerLastName}`}
                </p>
              </Col>
            </Row>
            <Row className="mx-3">
              <Col>
                <p className="bo-text">{t('pages.payment_details.employer')}</p>
              </Col>
              <Col>
                <p className="bo-text">{paymentDetails.aggregatorName}</p>
              </Col>
            </Row>
            <Row className="mx-3">
              <Col>
                <p className="bo-text">{t('pages.payment_details.advance_date')}</p>
              </Col>
              <Col>
                <p className="bo-text">
                  {moment(paymentDetails.fundingCreateDate).format('MMM DD, YYYY')}
                </p>
              </Col>
            </Row>
            <Row className="mx-3">
              <Col>
                <p className="bo-text">{t('pages.payment_details.total_amount')}</p>
              </Col>
              <Col>
                <p className="bo-text">${paymentDetails.fundingTotalAmount}</p>
              </Col>
            </Row>
            <Row className="mx-3">
              <Col>
                <p className="bo-text">{t('pages.payment_details.outstanding_amount')}</p>
              </Col>
              <Col>
                <p className="bo-text">${paymentDetails.fundingOutstandingAmount}</p>
              </Col>
            </Row>
            <hr />
            <Row className="mt-2 bo-h-30 mx-3">
              <Col className="bo-same-line">
                <p className="bo-text-bold bo-text-large m-0">
                  {t('pages.payment_details.payment_amount')}
                </p>
              </Col>
              {!editAmount ? (
                <Col className="bo-same-line">
                  <p className="bo-text-bold bo-text-large m-0">${paymentAmount}</p>
                  {paymentDetails.allowAmountChange && (
                    <Button
                      className="bo-button-inline-link ms-3 p-0"
                      color="link"
                      onClick={() => setEditAmount(true)}
                    >
                      {t('common.change')}
                    </Button>
                  )}
                </Col>
              ) : (
                <Col className="bo-same-line">
                  <Input
                    type="select"
                    className="bo-select bo-w-100"
                    onChange={e => {
                      setPaymentAmount(Number(e.target.value));
                      setSessionData('paymentAmount', e.target.value);
                    }}
                  >
                    {getAmountOptions().map(value => (
                      <option key={value} value={value}>
                        ${value}
                      </option>
                    ))}
                  </Input>
                </Col>
              )}
            </Row>
            {paymentIsNotAvailable && (
              <Row className="mt-5 mx-3">
                <Col className="d-flex">
                  <p className="bo-text bo-text-red">
                    {t('pages.payment_details.larger_than_outstanding')}
                  </p>
                </Col>
              </Row>
            )}
            <Row className="my-5 mx-3">
              <Col className="d-flex justify-content-center">
                <Button
                  className="bo-button px-4"
                  onClick={() => getCards()}
                  disabled={paymentIsNotAvailable}
                >
                  {t('pages.payment_details.continue_to_payment')}
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </Spinner>
  );
}

export default PaymentDetails;
