import OtpInput from './OtpInput';

export function CodeInput({ value, onChange }) {
  return (
    <OtpInput
      value={value}
      onChange={onChange}
      numInputs={6}
      renderInput={props => <input {...props} />}
      containerStyle="bo-code-container"
      inputStyle="bo-code-input"
      shouldAutoFocus
      inputType="number"
    />
  );
}
