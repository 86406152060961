import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from '../../components/Spinner';
import { useEffect } from 'react';
import { makeRequest } from '../../utils/makeRequest';
import { useEnv } from '../../context/env-context';
import { useSessionData } from '../../hooks/useSessionData';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

function AdminAccess() {
  const navigate = useNavigate();
  const { apiOriginFunding } = useEnv();
  const { getAccessTokenSilently } = useAuth0();
  const { sessionData, setSessionData } = useSessionData();

  const adminAuthenticate = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiOriginFunding}/ad_hoc_payment/admin_authenticate`,
      token,
      method: 'POST',
      data: JSON.stringify({ paymentLinkId: sessionData().linkId }),
    };

    try {
      const response = await makeRequest(config);

      setSessionData('accessToken', response.accessToken);
      navigate('/payment-details');
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    adminAuthenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row className="mt-4 mx-3">
      <Col className="d-flex justify-content-center align-items-center">
        <Spinner visible contentColor="#FFFFFF" />;
      </Col>
    </Row>
  );
}

export default AdminAccess;
