import { useNavigate } from 'react-router-dom';

export const useSessionData = () => {
  const navigate = useNavigate();

  const sessionData = () => ({
    error: window.sessionStorage.getItem('error'),
    errorState: window.sessionStorage.getItem('errorState'),
    linkId: window.sessionStorage.getItem('linkId'),
    consumer: JSON.parse(window.sessionStorage.getItem('consumer')),
    accessToken: window.sessionStorage.getItem('accessToken'),
    paymentAmount: window.sessionStorage.getItem('paymentAmount'),
    linkedCards: JSON.parse(window.sessionStorage.getItem('linkedCards')),
  });

  const setSessionData = (key, value) => window.sessionStorage.setItem(key, value);

  const gotoError = (error, errorState = null) => {
    window.sessionStorage.setItem('error', error);
    window.sessionStorage.setItem('errorState', errorState);
    navigate('/error');
  };

  return {
    sessionData,
    setSessionData,
    gotoError,
  };
};
