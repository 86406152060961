import { Col, Row } from 'reactstrap';
import logo from '../assets/nameLogo.png';

function Header() {
  return (
    <div>
      <Row className="mt-3 mx-3">
        <Col className="text-center mt-4">
          <img alt="logo" src={logo} className="name-logo" />
        </Col>
      </Row>
    </div>
  );
}

export default Header;
