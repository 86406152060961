import {
  BasisTheoryProvider,
  CardExpirationDateElement,
  CardNumberElement,
  CardVerificationCodeElement,
} from '@basis-theory/basis-theory-react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

function CardDetailsForm({
  bt,
  btElementsEvents,
  btMetadata,
  setBtElementsEvents,
  setBtMetadata,
  cardNumberRef,
  cardExpirationDateRef,
  cardVerificationCodeRef,
}) {
  const { t } = useTranslation();

  const getBtElementContainerStyle = fieldName => {
    let className = 'bo-bt-element-container';

    if (fieldName === 'cvc' || fieldName === 'cardExpirationDate') {
      className += ' bo-bt-element-w-100px';
    }

    if (
      btElementsEvents[fieldName] &&
      btElementsEvents[fieldName].dirty &&
      btElementsEvents[fieldName].changeEvent &&
      (btElementsEvents[fieldName].changeEvent.empty ||
        !btElementsEvents[fieldName].changeEvent.valid)
    ) {
      className += ' bo-bt-element-container-invalid';
    }

    if (
      fieldName === 'cardNumber' &&
      btElementsEvents.cardNumber.dirty &&
      btElementsEvents.cardNumber.changeEvent &&
      btElementsEvents.cardNumber.changeEvent.cardBrand !== 'mastercard' &&
      btElementsEvents.cardNumber.changeEvent.cardBrand !== 'visa'
    ) {
      className += ' bo-bt-element-container-invalid';
    }

    return className;
  };

  const btElementStyle = {
    base: {
      padding: 2,
      color: '#000000',
      fontSize: '16px',
      fontWeight: '400',
      '::placeholder': {
        color: '#979797',
      },
    },
    invalid: {
      color: '#000000',
    },
    complete: {
      color: '#000000',
    },
  };

  const getDefaultInputStyle = fieldName => {
    let className = 'bo-card-form-default-input';

    if (fieldName === 'firstName' || fieldName === 'lastName') {
      className += ' bo-card-form-name-input';
    }

    if (btMetadata[fieldName].dirty && !btMetadata[fieldName].value) {
      className += ' bo-card-form-default-input-invalid';
    }

    return className;
  };

  const handleBtElementReadyEvent = fieldName =>
    setBtElementsEvents(prevEvents => ({
      ...prevEvents,
      [fieldName]: {
        ...prevEvents[fieldName],
        ready: true,
      },
    }));

  const handleBtElementFocusEvent = fieldName =>
    setBtElementsEvents(prevEvents => ({
      ...prevEvents,
      [fieldName]: {
        ...prevEvents[fieldName],
        focused: true,
        dirty: true,
        changeEvent: prevEvents[fieldName].changeEvent,
      },
    }));

  const handleBtElementBlurEvent = fieldName =>
    setBtElementsEvents(prevEvents => ({
      ...prevEvents,
      [fieldName]: {
        ...prevEvents[fieldName],
        focused: false,
      },
    }));

  const handleBtElementChangeEvent = (fieldName, e) =>
    setBtElementsEvents(prevEvents => ({
      ...prevEvents,
      [fieldName]: {
        ...prevEvents[fieldName],
        changeEvent: prevEvents[fieldName].dirty ? e : null,
      },
    }));

  const handleDefaultInputChangeEvent = (fieldName, e) =>
    setBtMetadata(prevM => ({
      ...prevM,
      [fieldName]: { value: e.target.value, dirty: true },
    }));

  return (
    <Row className="mt-3 mx-3">
      <Col>
        <BasisTheoryProvider bt={bt}>
          <div className={getBtElementContainerStyle('cardNumber')}>
            <CardNumberElement
              id="cardNumber"
              ref={cardNumberRef}
              iconPosition="none"
              placeholder={t('pages.card_form.card_number_ph')}
              style={btElementStyle}
              onReady={() => handleBtElementReadyEvent('cardNumber')}
              onFocus={() => handleBtElementFocusEvent('cardNumber')}
              onChange={e => handleBtElementChangeEvent('cardNumber', e)}
              onBlur={() => handleBtElementBlurEvent('cardNumber')}
            />
          </div>
          <div className="d-flex justify-content-between">
            <div className={getBtElementContainerStyle('cardExpirationDate')}>
              <CardExpirationDateElement
                id="cardExpirationDate"
                ref={cardExpirationDateRef}
                placeholder={t('pages.card_form.exp_date_ph')}
                style={btElementStyle}
                onReady={() => handleBtElementReadyEvent('cardExpirationDate')}
                onFocus={() => handleBtElementFocusEvent('cardExpirationDate')}
                onChange={e => handleBtElementChangeEvent('cardExpirationDate', e)}
                onBlur={() => handleBtElementBlurEvent('cardExpirationDate')}
              />
            </div>
            <div className={getBtElementContainerStyle('cvc')}>
              <CardVerificationCodeElement
                id="cardVerificationCode"
                ref={cardVerificationCodeRef}
                placeholder={t('pages.card_form.cvc_ph')}
                style={btElementStyle}
                onReady={() => handleBtElementReadyEvent('cvc')}
                onFocus={() => handleBtElementFocusEvent('cvc')}
                onChange={e => handleBtElementChangeEvent('cvc', e)}
                onBlur={() => handleBtElementBlurEvent('cvc')}
              />
            </div>
          </div>
        </BasisTheoryProvider>
        <div className="d-flex justify-content-between">
          <input
            id="firstName"
            className={getDefaultInputStyle('firstName')}
            placeholder={t('pages.card_form.first_name_ph')}
            value={btMetadata.firstName.value}
            onChange={e => handleDefaultInputChangeEvent('firstName', e)}
          />
          <input
            id="lastName"
            className={getDefaultInputStyle('lastName')}
            placeholder={t('pages.card_form.last_name_ph')}
            value={btMetadata.lastName.value}
            onChange={e => handleDefaultInputChangeEvent('lastName', e)}
          />
        </div>
      </Col>
    </Row>
  );
}

export default CardDetailsForm;
