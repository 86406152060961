import { useEffect, useState } from 'react';
import { Spinner } from '../../components/Spinner';
import { Button, Col, Container, Row } from 'reactstrap';
import { useSessionData } from '../../hooks/useSessionData';
import { Trans, useTranslation } from 'react-i18next';
import { useEnv } from '../../context/env-context';
import { makeRequest } from '../../utils/makeRequest';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import CardProviderImage from '../../components/CardProviderImage';
import Header from '../../components/Header';

function Card() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { apiOriginFunding, apiOriginConsumer } = useEnv();
  const { sessionData, setSessionData, gotoError } = useSessionData();

  const [cardDetails, setCardDetails] = useState(null);
  const [showError, setShowError] = useState('');
  const [transferId, setTransferId] = useState(null);
  const [spinner, setSpinner] = useState(true);

  const getCards = async () => {
    setSpinner(true);

    const config = {
      url: `${apiOriginConsumer}/cards/get`,
      token: sessionData().accessToken,
      method: 'GET',
    };

    try {
      const response = await makeRequest(config);

      if (response.length === 1 && response[0].status === 'OK') {
        setCardDetails(response[0]);
      } else if (response.length === 1 && response[0].status !== 'OK') {
        toast.error(t('pages.card.errors.not_valid'));
        setSessionData('linkedCards', JSON.stringify(response));
        navigate('/linked-cards');
      } else {
        const newCards = response.filter(
          currCard =>
            !sessionData().linkedCards.some(prevCard => prevCard.cardId === currCard.cardId),
        );

        if (newCards.length === 0 || newCards.length > 1) {
          toast.error(t('pages.card.errors.uknown_card_error'));
          setSessionData('linkedCards', JSON.stringify(response));
          navigate('/linked-cards');
        } else if (newCards.length === 1 && newCards[0].status !== 'OK') {
          toast.error(t('pages.card.errors.not_valid'));
          setSessionData('linkedCards', JSON.stringify(response));
          navigate('/linked-cards');
        } else {
          setCardDetails(newCards[0]);
        }
      }
    } catch (error) {
      gotoError(t('errors.cards_error'));
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTransferStatus = async startTime => {
    const config = {
      url: `${apiOriginFunding}/get_transfer_status`,
      token: sessionData().accessToken,
      method: 'GET',
      params: {
        transferId,
      },
    };

    try {
      const response = await makeRequest(config);

      if (response.transferStatus === 'CREATED') {
        if (moment().diff(startTime, 'seconds') <= 10) {
          setTimeout(() => getTransferStatus(startTime), 3000);
        } else {
          setSpinner(false);
          navigate('/success');
        }
      } else if (response.transferStatus === 'COMPLETED') {
        setSpinner(false);
        navigate('/success');
      } else if (response.transferStatus === 'CANCELLED') {
        setSpinner(false);
        setShowError('cancelled');
      } else {
        setSpinner(false);
        gotoError('FAILED', 'payment');
      }
    } catch (error) {
      gotoError(t('errors.transfer_status_error'));
    }
  };

  useEffect(() => {
    if (transferId) {
      const startTime = moment();

      getTransferStatus(startTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferId]);

  const pay = async () => {
    setSpinner(true);

    const config = {
      url: `${apiOriginFunding}/ad_hoc_payment/pay`,
      token: sessionData().accessToken,
      method: 'POST',
      data: {
        paymentLinkId: sessionData().linkId,
        cardId: cardDetails.cardId,
        amount: Number(sessionData().paymentAmount),
      },
    };

    try {
      const response = await makeRequest(config);

      if (response.transferId) {
        setTransferId(response.transferId);
      }
    } catch (error) {
      gotoError(t('errors.payment_error'));
    }
  };

  return (
    <Spinner
      visible={spinner}
      text={transferId && t('common.payment.waiting_for_approval')}
      wrapperFlexGrow={1}
    >
      <Container className="bo-mobile">
        <Header />
        <div className="bo-rounded-container mt-4">
          <Row className="mt-4 mx-3">
            <Col>
              <p className="bo-text mb-0">
                <Trans t={t} i18nKey="pages.card.about_to_pay">
                  You are about to pay
                  <strong>${{ amount: sessionData().paymentAmount }}</strong>
                  with the following card
                </Trans>
              </p>
              {showError === 'cancelled' && (
                <div className="bo-text bo-payment-error-container">
                  {t('common.payment.cancelled')}
                </div>
              )}
            </Col>
          </Row>
          {cardDetails && (
            <Row className="mt-3 mx-3">
              <Col>
                <div className="bo-card-container">
                  <div className="d-flex justify-content-between">
                    <p className="bo-text mb-0">
                      {`${cardDetails.firstName} ${cardDetails.lastName}`}
                    </p>
                    <CardProviderImage company={cardDetails.company} />
                  </div>
                  <p className="bo-text mb-0">{`**** **** **** ${cardDetails.last4Digits}`}</p>
                  <p className="bo-text mb-0">
                    {`${t('pages.linked_cards.exp')} ${cardDetails.expiration.toUpperCase()}`}
                  </p>
                </div>
              </Col>
            </Row>
          )}
          <Row className="mt-4 mx-3">
            <Col className="d-flex justify-content-center">
              <Button
                className="bo-button px-4"
                disabled={!cardDetails || spinner || (cardDetails && cardDetails.isExpired)}
                onClick={() => pay()}
              >
                {t('pages.card.pay')}
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </Spinner>
  );
}

export default Card;
