import { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { makeUnauthorizedRequest } from '../../utils/makeRequest';
import { useEnv } from '../../context/env-context';
import { Spinner } from '../../components/Spinner';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSessionData } from '../../hooks/useSessionData';
import EnvironmentFooter from '../../components/EnvironmentFooter';
import { useAuth0 } from '@auth0/auth0-react';
import Header from '../../components/Header';

export function Welcome() {
  const { linkId } = useParams();
  const { loginWithRedirect } = useAuth0();

  const navigate = useNavigate();
  const { setSessionData, gotoError } = useSessionData();

  const { t, i18n } = useTranslation();

  const { apiOriginFunding } = useEnv();

  const [spinner, setSpinner] = useState(false);
  const [mfaDetails, setMfaDetails] = useState(null);

  const sendMfa = async () => {
    setSpinner(true);

    const config = {
      url: `${apiOriginFunding}/ad_hoc_payment/send_mfa`,
      method: 'POST',
      data: JSON.stringify({ paymentLinkId: linkId }),
    };

    try {
      const response = await makeUnauthorizedRequest(config);

      setSessionData('consumer', JSON.stringify(response));
      navigate('/auth');
    } catch (error) {
      gotoError(t('errors.send_mfa_error'));
    } finally {
      setSpinner(false);
    }
  };

  const getMfaDetails = async () => {
    setSpinner(true);

    const config = {
      url: `${apiOriginFunding}/ad_hoc_payment/get_mfa`,
      method: 'GET',
      params: { paymentLinkId: linkId },
    };

    try {
      const response = await makeUnauthorizedRequest(config);

      setMfaDetails(response);
      setSessionData('linkId', linkId);
    } catch (error) {
      gotoError(t('errors.mfa_details_error'));
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    if (linkId) {
      getMfaDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkId]);

  useEffect(() => {
    if (mfaDetails && mfaDetails.language) {
      i18n.changeLanguage(mfaDetails.language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mfaDetails?.language]);

  useEffect(() => {
    if (mfaDetails && mfaDetails.paymentByAdmin) {
      loginWithRedirect({
        appState: {
          returnTo: '/admin-access',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mfaDetails]);

  if (!linkId) {
    return null;
  }

  return (
    <Spinner visible={spinner} wrapperFlexGrow={1}>
      {mfaDetails && !mfaDetails.paymentByAdmin && (
        <Container className="bo-mobile">
          <Header />
          <div className="bo-rounded-container mt-4">
            <Row className="mt-4 mx-3">
              <Col>
                <p className="bo-larger text-center">{t('common.welcome_to_payments')}</p>
                <p className="bo-text text-center mt-2 py-3">
                  {mfaDetails.mfaChannel === 'SMS'
                    ? t('pages.welcome.send_a_code_phone')
                    : t('pages.welcome.send_a_code_email')}
                </p>
                <p className="bo-text text-center my-3 text-secondary fs-5">{mfaDetails.mfaMask}</p>
              </Col>
            </Row>
            <Row className="mt-5 mx-3">
              <Col className="d-flex justify-content-center">
                <Button className="bo-button px-4" onClick={sendMfa}>
                  {t('pages.welcome.send_code')}
                </Button>
              </Col>
            </Row>
            <EnvironmentFooter />
          </div>
        </Container>
      )}
    </Spinner>
  );
}
export default Welcome;
