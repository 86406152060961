import { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Badge, Button, Col, Container, Row } from 'reactstrap';
import { Spinner } from '../../components/Spinner';
import { useSessionData } from '../../hooks/useSessionData';
import { useEnv } from '../../context/env-context';
import { useNavigate, Link } from 'react-router-dom';
import { makeRequest } from '../../utils/makeRequest';
import CardProviderImage from '../../components/CardProviderImage';
import moment from 'moment';
import Header from '../../components/Header';

function LinkedCards() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { apiOriginFunding } = useEnv();
  const { sessionData, gotoError } = useSessionData();

  const [spinner, setSpinner] = useState(false);
  const [showError, setShowError] = useState('');
  const [transferId, setTransferId] = useState(null);

  const getTransferStatus = async startTime => {
    const config = {
      url: `${apiOriginFunding}/get_transfer_status`,
      token: sessionData().accessToken,
      method: 'GET',
      params: {
        transferId,
      },
    };

    try {
      const response = await makeRequest(config);

      if (response.transferStatus === 'CREATED') {
        if (moment().diff(startTime, 'seconds') <= 10) {
          setTimeout(() => getTransferStatus(startTime), 3000);
        } else {
          setSpinner(false);
          navigate('/success');
        }
      } else if (response.transferStatus === 'COMPLETED') {
        setSpinner(false);
        navigate('/success');
      } else if (response.transferStatus === 'CANCELLED') {
        setSpinner(false);
        setShowError('cancelled');
      } else {
        setSpinner(false);
        setShowError('declined');
      }
    } catch (error) {
      gotoError(t('errors.transfer_status_error'));
    }
  };

  const pay = async cardId => {
    setSpinner(true);

    const config = {
      url: `${apiOriginFunding}/ad_hoc_payment/pay`,
      token: sessionData().accessToken,
      method: 'POST',
      data: {
        paymentLinkId: sessionData().linkId,
        cardId,
        amount: Number(sessionData().paymentAmount),
      },
    };

    try {
      const response = await makeRequest(config);

      if (response.transferId) {
        setTransferId(response.transferId);
      }
    } catch (error) {
      gotoError(t('errors.payment_error'));
      setSpinner(false);
    }
  };

  useEffect(() => {
    if (transferId) {
      const startTime = moment();

      getTransferStatus(startTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferId]);

  const newCardLink = '/card-form';

  const linkedAvailableCards = sessionData().linkedCards.filter(card => card.status === 'OK');

  return (
    <Spinner
      visible={spinner}
      text={transferId && t('common.payment.waiting_for_approval')}
      wrapperFlexGrow={1}
    >
      {linkedAvailableCards && linkedAvailableCards.length > 0 && (
        <Container className="bo-mobile">
          <Header />
          <div className="bo-rounded-container mt-4">
            <Row className="mt-4 mx-3">
              <Col>
                {linkedAvailableCards.length === 1 ? (
                  <>
                    <p className="bo-text">{t('pages.linked_cards.one_card')}</p>
                    <p className="bo-text">
                      <Trans>
                        Please select if you would like to pay with this card, or
                        <Link to={newCardLink} className="bo-link">
                          a different one.
                        </Link>
                      </Trans>
                    </p>
                  </>
                ) : (
                  <>
                    <p className="bo-text">{t('pages.linked_cards.multiple_cards')}</p>
                    <p className="bo-text">
                      <Trans t={t} i18nKey="pages.linked_cards.select_multiple">
                        Please select a card for this payment, or
                        <Link to={newCardLink} className="bo-link">
                          pay with a different card.
                        </Link>
                      </Trans>
                    </p>
                  </>
                )}
                {showError && (
                  <div className="bo-text bo-payment-error-container">
                    {showError === 'cancelled'
                      ? t('common.payment.cancelled')
                      : t('common.payment.declined')}
                  </div>
                )}
              </Col>
            </Row>
            {linkedAvailableCards.map(card => (
              <Row key={`${card.last4Digits}_${card.expiration}`} className="mt-3 mx-3">
                <Col>
                  <div className={`bo-card-container ${card.isAdhoc && 'bo-bg-gray'}`}>
                    <div className="d-flex justify-content-between">
                      <p className={`bo-text mb-0 ${card.isAdhoc && 'text-secondary'}`}>
                        {`${card.firstName} ${card.lastName}`}
                      </p>
                      <CardProviderImage company={card.company} />
                    </div>
                    <p className={`bo-text mb-0 ${card.isAdhoc && 'text-secondary'}`}>
                      {`**** **** **** ${card.last4Digits}`}
                    </p>
                    <p className={`bo-text mb-0 ${card.isAdhoc && 'text-secondary'}`}>
                      {`${t('pages.linked_cards.exp')} ${card.expiration.toUpperCase()}`}
                    </p>
                    {card.isExpired ? (
                      <div className="d-flex mt-2 mb-1">
                        <Badge color="danger" disabled>
                          <p className="bo-text mb-0 p-1">{t('pages.linked_cards.expired')}</p>
                        </Badge>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center mt-4 mb-2">
                        <Button
                          className="bo-button px-4"
                          onClick={() => pay(card.cardId)}
                          disabled={spinner}
                        >
                          {t('pages.linked_cards.pay_with_this_card')}
                        </Button>
                      </div>
                    )}
                    {card.isAdhoc && (
                      <p className="bo-text mb-0 text-secondary fst-italic bo-text-xs">
                        {`${t('pages.linked_cards.adhoc_card')}`}
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
            ))}
            <Row className="my-4 mx-3">
              <Col>
                <Link to={newCardLink} className="bo-link">
                  {t('pages.linked_cards.pay_with_different_card')}
                </Link>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </Spinner>
  );
}

export default LinkedCards;
