import { faAt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

function ContactInfo() {
  const { t } = useTranslation();

  const mailTo = 'mailto:support@bridgeover.io';
  const phoneTo = 'tel:18004724230';

  return (
    <Row className="mx-3">
      <Col>
        <div className="mt-2">
          <div className="mb-2 d-flex">
            <div className="bo-w-25">
              <FontAwesomeIcon icon={faAt} color="black" />
            </div>
            <div className="d-flex">
              <p className="bo-w-80">{t('components.contact_info.email')}</p>
              <a className="bo-link" href={mailTo}>
                support@bridgeover.io
              </a>
            </div>
          </div>
          <div className="mb-2 d-flex">
            <div className="bo-w-25">
              <FontAwesomeIcon icon={faPhone} color="black" />
            </div>
            <div className="d-flex">
              <p className="bo-w-80">{t('components.contact_info.phone')}</p>
              <a className="bo-link" href={phoneTo}>
                1-800-472-4230
              </a>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ContactInfo;
