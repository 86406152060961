import {
  BasisTheoryApiError,
  BasisTheoryValidationError,
  useBasisTheory,
} from '@basis-theory/basis-theory-react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { Spinner } from '../../components/Spinner';
import { states } from '../../utils/states';
import { useEnv } from '../../context/env-context';
import { useSessionData } from '../../hooks/useSessionData';
import { makeRequest } from '../../utils/makeRequest';
import CardDetailsForm from './components/CardDetailsForm/CardDetailsForm';
import BillingAddressForm from './components/BillingAddressForm/BillingAddressForm';
import CardProviderImage from '../../components/CardProviderImage';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';

function CardForm() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { apiOriginConsumer } = useEnv();
  const { sessionData, gotoError } = useSessionData();

  const { bt } = useBasisTheory(process.env.REACT_APP_BT_PUBLIC_KEY, { elements: true });

  const cardNumberRef = useRef(null);
  const cardExpirationDateRef = useRef(null);
  const cardVerificationCodeRef = useRef(null);

  const [btElementsEvents, setBtElementsEvents] = useState({
    cardExpirationDate: {
      ready: false,
      focused: false,
      dirty: false,
      changeEvent: null,
    },
    cvc: {
      ready: false,
      focused: false,
      dirty: false,
      changeEvent: null,
    },
    cardNumber: {
      ready: false,
      focused: false,
      dirty: false,
      changeEvent: null,
    },
  });

  const [btMetadata, setBtMetadata] = useState({
    firstName: { value: '', dirty: false },
    lastName: { value: '', dirty: false },
    addressLine1: { value: '', dirty: false },
    addressLine2: { value: '', dirty: false },
    city: { value: '', dirty: false },
    state: { value: '', dirty: false },
    zip: { value: '', dirty: false },
  });

  const [spinner, setSpinner] = useState(false);
  const [keepCard, setKeepCard] = useState(false);
  const [consumerAddress, setConsumerAddress] = useState(null);
  const [useProfileAddress, setUseProfileAddress] = useState(true);

  useEffect(() => {
    if (useProfileAddress && consumerAddress) {
      const { addressLine1, addressLine2, city, state, zip } = consumerAddress;

      setBtMetadata(prevM => ({
        ...prevM,
        addressLine1: { value: addressLine1, dirty: false },
        addressLine2: { value: addressLine2, dirty: false },
        city: { value: city, dirty: false },
        state: { value: state, dirty: false },
        zip: { value: zip, dirty: false },
      }));
    } else {
      setBtMetadata(prevM => ({
        ...prevM,
        addressLine1: { value: '', dirty: false },
        addressLine2: { value: '', dirty: false },
        city: { value: '', dirty: false },
        state: { value: '', dirty: false },
        zip: { value: '', dirty: false },
      }));
    }
  }, [useProfileAddress, consumerAddress]);

  const getConsumerAddress = async () => {
    setSpinner(true);

    const config = {
      url: `${apiOriginConsumer}/get_my_details/v2`,
      token: sessionData().accessToken,
      method: 'GET',
    };

    try {
      const response = await makeRequest(config);

      if (response) {
        setConsumerAddress(response);
      }
    } catch (error) {
      gotoError(t('common.unknown_error'));
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getConsumerAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createBtToken = async () => {
    try {
      const btToken = await bt?.tokens.create({
        type: 'card',
        data: {
          number: cardNumberRef.current,
          expiration_month: cardExpirationDateRef.current.month(),
          expiration_year: cardExpirationDateRef.current.year(),
          cvc: cardVerificationCodeRef.current,
        },
        metadata: {
          firstName: btMetadata.firstName.value,
          lastName: btMetadata.lastName.value,
          addressLine1: btMetadata.addressLine1.value,
          addressLine2: btMetadata.addressLine2.value || undefined,
          city: btMetadata.city.value,
          state: btMetadata.state.value,
          zip: btMetadata.zip.value,
        },
      });

      return btToken;
    } catch (error) {
      if (error instanceof BasisTheoryValidationError) {
        console.error(error);
      } else if (error instanceof BasisTheoryApiError) {
        console.error(error);
      }

      gotoError(t('common.unknown_error'));

      return null;
    }
  };

  const linkCard = async () => {
    setSpinner(true);

    const btToken = await createBtToken();

    try {
      const config = {
        url: `${apiOriginConsumer}/cards/link`,
        token: sessionData().accessToken,
        method: 'POST',
        data: JSON.stringify({ btTokenId: btToken.id, isAdhoc: !keepCard, allowPending: false }),
      };

      await makeRequest(config);

      navigate('/card');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const formValid =
    btElementsEvents.cardNumber.ready &&
    btElementsEvents.cardNumber.changeEvent &&
    btElementsEvents.cardNumber.changeEvent.valid &&
    (btElementsEvents.cardNumber.changeEvent.cardBrand === 'mastercard' ||
      btElementsEvents.cardNumber.changeEvent.cardBrand === 'visa') &&
    btElementsEvents.cardExpirationDate.ready &&
    btElementsEvents.cardExpirationDate.changeEvent &&
    btElementsEvents.cardExpirationDate.changeEvent.valid &&
    btElementsEvents.cvc.ready &&
    btElementsEvents.cvc.changeEvent &&
    btElementsEvents.cvc.changeEvent.valid &&
    btMetadata.firstName.value.length !== 0 &&
    btMetadata.lastName.value.length !== 0 &&
    btMetadata.addressLine1.value.length !== 0 &&
    btMetadata.city.value.length !== 0 &&
    states.includes(btMetadata.state.value) &&
    btMetadata.zip.value.length === 5;

  return (
    <Spinner
      visible={
        spinner ||
        !btElementsEvents.cardNumber.ready ||
        !btElementsEvents.cardExpirationDate.ready ||
        !btElementsEvents.cvc.ready
      }
      wrapperFlexGrow={1}
    >
      <Container className="bo-mobile">
        <Header />
        <div className="bo-rounded-container mt-4">
          <Row className="mt-4 mx-3">
            <Col className="d-flex justify-content-center align-items-center">
              <CardProviderImage company="visa" height={20} className="me-3" />
              <CardProviderImage company="mastercard" height={30} />
            </Col>
          </Row>
          <CardDetailsForm
            bt={bt}
            btElementsEvents={btElementsEvents}
            btMetadata={btMetadata}
            setBtElementsEvents={setBtElementsEvents}
            setBtMetadata={setBtMetadata}
            cardNumberRef={cardNumberRef}
            cardExpirationDateRef={cardExpirationDateRef}
            cardVerificationCodeRef={cardVerificationCodeRef}
          />
          <BillingAddressForm
            btMetadata={btMetadata}
            setBtMetadata={setBtMetadata}
            useProfileAddress={useProfileAddress}
            setUseProfileAddress={setUseProfileAddress}
          />
          <Row className="mt-3 mx-3">
            <Col className="bo-same-line align-items-start">
              <FormGroup switch>
                <Input
                  name="keepCard"
                  id="keepCard"
                  type="switch"
                  role="switch"
                  className="bo-switch-scale mt-2"
                  checked={keepCard}
                  onChange={e => setKeepCard(e.target.checked)}
                />
                <Label className="bo-text ms-4 mb-0">{t('pages.card_form.keep_this_card')}</Label>
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-4 mx-0">
            <Col className="d-flex justify-content-center">
              <Button className="bo-button px-4" onClick={() => linkCard()} disabled={!formValid}>
                {t('common.continue')}
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </Spinner>
  );
}

export default CardForm;
